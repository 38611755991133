import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { App } from 'antd';
import { TUISkeleton, useUserDispatch } from 'tengits-ui5';
import AccountBindModal from './components/AccountBindModal';
import { LoginService, loginCallback } from './constants';

// 扫码登录回调 - 重定向页面
const CodeRedirectPage = () => {
  const [open, setOpen] = useState(false);
  const [bindInfo, setBindInfo] = useState(null);
  const navigate = useNavigate();
  const dispatch = useUserDispatch();
  const { project, type } = useParams();
  const { message } = App.useApp();

  useEffect(() => {
    LoginService({
      type,
      dispatch,
      onNotBind: (r) => {
        setOpen(true);
        setBindInfo(r.message);
      }
    })
  }, [])

  const onCancel = () => {
    setOpen(false);
    setBindInfo(null);
    loginCallback();
  }

  return (
    <div className='codeRedirectPage'>
      <TUISkeleton />
      <AccountBindModal
        open={open}
        onCancel={onCancel}
        bindInfo={bindInfo}
      />
    </div>
  )
}

export default CodeRedirectPage;