import ClassNames from 'classnames';
import { useThemeContext } from '../../index';
import './index.less';

export default function BannerTop({
  title,
  text,
  backImg,
  backImg1 = 'https://static-1255466169.cos.ap-guangzhou.myqcloud.com/ThemeAssets/banner/banner1.png',
  backImg2 = 'https://static-1255466169.cos.ap-guangzhou.myqcloud.com/ThemeAssets/banner/banner2.png',
  backImg3 = 'https://static-1255466169.cos.ap-guangzhou.myqcloud.com/ThemeAssets/banner/banner3.png',
  backImg4 = 'https://static-1255466169.cos.ap-guangzhou.myqcloud.com/ThemeAssets/banner/banner4.png',
  OtherSolt,
  className = '',
  style = {},
}) {
  const { sysMode, themeType } = useThemeContext();

  const getBannerImg = ({ sysMode, themeType, backImg }) => {
    if (backImg)
      return backImg;

    if (sysMode === 'dark') {
      return backImg4;
    }
    else {
      if (themeType === 'theme1')
        return backImg1;

      if (themeType === 'theme2')
        return backImg2;

      if (themeType === 'theme3')
        return backImg3;

      if (themeType === 'theme4')
        return backImg4;

      return backImg1;
    }
  };

  return (
    <div className={ClassNames(`TUI-BannerTop TUI-BannerTop-${themeType}`, className)} style={{ ...style }}>
      {
        OtherSolt || (
          <>
            <div className="title">{title}</div>
            {text && <div className="text">{text}</div>}
          </>
        )
      }
      <img src={getBannerImg({ sysMode, themeType, backImg })} className="imgSty" />
    </div>
  );
}
