import { message } from "antd";
import axios from "axios";
import request from "../../../src/utils/request";
import { getSearchParams } from "tengits-fe-utils";

// 获取登录页配置信息
const getTenantPara = () => {
  return JSON.parse(localStorage.getItem('TenantSession')) || {};
}

/** 登录saas后台管理 */
export const loginCallback = () => {
  const tenantPara = getTenantPara();
  window.location.href = tenantPara.pageType === 2 ? '/home' : '/'
}

export const login = ({ token }) => {
  const tenantPara = getTenantPara();
  return request('/ttos/user/getUserInfo', {
    Authorization: token,
    ...tenantPara,
    currentTenantId: tenantPara.tenantId,
    tenantId: undefined,
  }).then(async (r) => {
    if (r.status === 0) {
      const userInfo = {
        ...r.data,
        simpleName: (r.data.username || '').substring(0, 4),
        account: r.data.phoneNum,
        name: r.data.username,
        phone: r.data.phoneNum,
        permissions: [],
      }
      return userInfo;
    }
    return null;
  })
}

/** 判断是否绑定 是-登录 否-弹窗绑定 */
export const LoginService = async ({ dispatch, type, onNotBind }) => {
  const query = getSearchParams() as { code: string, state: string };
  return await axios.get(`/sign/logon/oauth20/callback/${type}`, { params: query }).then(async (r) => {
    // 绑定成功
    if (r.data.code === 0) {
      const token = r.data.data.cookie;
      localStorage.setItem('token', token);
      // 拉用户信息
      login({ token }).then((userInfo) => {
        if (!userInfo) return message.error('用户信息获取失败')
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        // 存到全局useUserContext
        dispatch({ type: 'replaceData', payload: userInfo })
        loginCallback()
      })
    } else if (r.data.code === 102) {
      // 未绑定
      onNotBind(r.data)
    } else {
      message.error(r.data.message || '处理失败，请稍后重试')
    }
  })
}

// 绑定成功后登录
export const bindLogin = ({ params, dispatch }) => {
  request('/ttos/authremote/bindUserSocial', params).then(res => {
    if (res.status === 0) {
      const { cookie } = res.data;
      localStorage.setItem('token', cookie);
      login({ token: cookie }).then((userInfo) => {
        if (!userInfo) return message.error('用户信息获取失败')
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        // 存到全局useUserContext里
        dispatch({ type: 'replaceData', payload: userInfo })
        loginCallback()
      })
    } else {
      message.error(r.message || '绑定失败，请稍后重试')
    }
  })
}
